import { ClientBFFClient } from "@finbackoffice/clientbff-client";
import { PopupTags, SiteContentScopes } from "@finbackoffice/enums";
import { AccountConfigType, SignupConfigType } from "@finbackoffice/fe-core";
import { AxiosLikeErrorMiddleware } from "@finbackoffice/http-client";
import {
    AffiliateTrackerProvider,
    AuthContext,
    AuthProvider,
    BannersProvider,
    BetSlipProvider,
    BrowserStorageIdsEnum,
    BrowserStorageProvider,
    CasinoFavoritesProvider,
    ChatProvider,
    ChatServiceProvider,
    ClientProvider,
    ConfigProvider,
    CrmProvider,
    ExchangeRatesProvider,
    FeedProvider,
    I18nextProvider,
    IOSAuthorizationMiddleware,
    LocaleProvider,
    ModalsProvider,
    PopupBannersProvider,
    RuntimeConfigProvider,
    SiteNotificationsProvider,
    UserAccountProvider,
    useRuntimeConfig,
    useSiteConfig,
} from "@finbackoffice/site-core";
import { captureException } from "@sentry/nextjs";
import { NextComponentType, Page } from "next";
import type { AppContext, AppInitialProps, AppLayoutProps, AppProps } from "next/app";
import { useRouter } from "next/router";
import { FC, PropsWithChildren, ReactNode, useContext, useEffect, useMemo } from "react";
import CookiesConsent from "components/cookie-consent/CookiesConsent";
import Header from "components/header/Header";
import Menu from "components/menu/Menu";
import {
    CasinoToolsProvider,
    MarketUpdatesProvider,
    NotificationProvider,
    PrematchProvider,
    SIRWidgetProvider,
    SportResultsProvider,
    InitialDataProvider,
    WebsocketProvider,
} from "contexts";
import { CommonSsrProps } from "services/ssr";
import { generateUUID } from "utils/helpers";
import "../styles/base.sass";
import "../styles/common-variables.sass";
import "../styles/normalize.sass";
import ChatScript from "components/base/external-scripts/ChatScript";
import FloatingLogin from "components/floating-login/FloatingLogin";
import { initializeGoogleTagManager } from "../../public/assets/js/gtm";

type IProvidersProps = PropsWithChildren & {
    pageProps: CommonSsrProps;
};

const Providers: FC<IProvidersProps> = ({ children, pageProps }) => {
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const accountFields = useSiteConfig<AccountConfigType[]>("accountFields");
    const signupFields = useSiteConfig<SignupConfigType[]>("signupFields");
    const { setToken, isUserLoggedIn } = useContext(AuthContext);
    const router = useRouter();

    const client = useMemo(() => {
        const origin =
            typeof window !== "undefined" && window.location.origin ? window.location.origin : "";

        return new ClientBFFClient(`${origin}/api/proxy/`, "sportsbook-mobile", [
            new IOSAuthorizationMiddleware(BrowserStorageIdsEnum.TOKEN, (token: string) => {
                setToken(token);
            }),
            new AxiosLikeErrorMiddleware({
                handleNotOkStatusCode(error, response) {
                    if (response.statusCode === 401) {
                        setToken("");
                    } else if (response.statusCode >= 500) {
                        const traceparent = response?.headers?.traceparent;
                        const traceId = traceparent ? traceparent?.split("-")[1] : "unknown";
                        captureException(error, {
                            tags: {
                                // eslint-disable-next-line @typescript-eslint/naming-convention
                                "trace-id": traceId,
                            },
                        });
                    }
                },
            }),
        ]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        async function setFallbackToken() {
            // inside iframe on ios
            if ("hasStorageAccess" in document && !(await document.hasStorageAccess())) {
                setToken(localStorage.getItem(BrowserStorageIdsEnum.TOKEN) || "");
            }
        }
        void setFallbackToken();
    }, [setToken]);

    useEffect(() => {
        if (!!COMMON_SITE_CONFIGS.google.tagManagerId) {
            initializeGoogleTagManager(COMMON_SITE_CONFIGS.google.tagManagerId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <LocaleProvider locale={pageProps.locale}>
            <I18nextProvider translations={pageProps.translations}>
                <BrowserStorageProvider>
                    <ClientProvider client={client}>
                        <UserAccountProvider
                            initialProfile={pageProps.profile}
                            uuid={generateUUID()}>
                            <ConfigProvider
                                defaultConfig={pageProps.configs}
                                accountFields={accountFields}
                                signupFields={signupFields}
                                defaultCountry={COMMON_SITE_CONFIGS.country.default}
                                walletConfigs={COMMON_SITE_CONFIGS.wallet}>
                                <ExchangeRatesProvider
                                    fxCurrency={COMMON_SITE_CONFIGS.wallet.fxCurrency}>
                                    <WebsocketProvider>
                                        <FeedProvider>
                                            <InitialDataProvider>
                                                <ChatProvider>
                                                    <BetSlipProvider>
                                                        <MarketUpdatesProvider>
                                                            <NotificationProvider>
                                                                <CasinoFavoritesProvider
                                                                    favoriteGames={
                                                                        pageProps.casinoFavoriteGames ||
                                                                        null
                                                                    }
                                                                    mobile>
                                                                    <ModalsProvider>
                                                                        <CasinoToolsProvider>
                                                                            <SiteNotificationsProvider
                                                                                ignore={
                                                                                    router.query
                                                                                        .isTest ===
                                                                                    "true"
                                                                                }
                                                                                appName={
                                                                                    COMMON_SITE_CONFIGS.appName
                                                                                }>
                                                                                <PopupBannersProvider
                                                                                    popups={
                                                                                        pageProps.popupBanners ||
                                                                                        []
                                                                                    }
                                                                                    device={
                                                                                        PopupTags.Mobile
                                                                                    }
                                                                                    ignore={
                                                                                        router.query
                                                                                            .isTest ===
                                                                                        "true"
                                                                                    }>
                                                                                    <BannersProvider
                                                                                        device={
                                                                                            SiteContentScopes.Mobile
                                                                                        }
                                                                                        banners={
                                                                                            pageProps.banners
                                                                                        }>
                                                                                        <SIRWidgetProvider>
                                                                                            <PrematchProvider>
                                                                                                <SportResultsProvider>
                                                                                                    <CrmProvider>
                                                                                                        <AffiliateTrackerProvider>
                                                                                                            <ChatServiceProvider
                                                                                                                script={
                                                                                                                    <ChatScript
                                                                                                                        isUserLoggedIn={
                                                                                                                            isUserLoggedIn
                                                                                                                        }
                                                                                                                    />
                                                                                                                }>
                                                                                                                {
                                                                                                                    children
                                                                                                                }
                                                                                                            </ChatServiceProvider>
                                                                                                        </AffiliateTrackerProvider>
                                                                                                    </CrmProvider>
                                                                                                </SportResultsProvider>
                                                                                            </PrematchProvider>
                                                                                        </SIRWidgetProvider>
                                                                                    </BannersProvider>
                                                                                </PopupBannersProvider>
                                                                            </SiteNotificationsProvider>
                                                                        </CasinoToolsProvider>
                                                                    </ModalsProvider>
                                                                </CasinoFavoritesProvider>
                                                            </NotificationProvider>
                                                        </MarketUpdatesProvider>
                                                    </BetSlipProvider>
                                                </ChatProvider>
                                            </InitialDataProvider>
                                        </FeedProvider>
                                    </WebsocketProvider>
                                </ExchangeRatesProvider>
                            </ConfigProvider>
                        </UserAccountProvider>
                    </ClientProvider>
                </BrowserStorageProvider>
            </I18nextProvider>
        </LocaleProvider>
    );
};

type Props = AppProps & {
    Component: Page;
};

const MyApp: NextComponentType<AppContext, AppInitialProps, AppLayoutProps<object>> = ({
    Component,
    pageProps,
}: Props) => {
    const router = useRouter();
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const { isUserLoggedIn } = useContext(AuthContext);
    const getLayout = Component.getLayout || ((page: ReactNode) => page);

    const mainLayout = (
        <>
            <Header />
            {getLayout(<Component {...pageProps} />, router)}
            <Menu />
        </>
    );

    return (
        <section className="page">
            {mainLayout}
            {COMMON_SITE_CONFIGS.cookiesConsentPopup && <CookiesConsent />}
            {COMMON_SITE_CONFIGS.login.floatingLogin && !isUserLoggedIn && <FloatingLogin />}
        </section>
    );
};

export default ({ Component, ...rest }: AppProps) => (
    <AuthProvider initialJwt={rest.pageProps.jwt}>
        <RuntimeConfigProvider config={rest.pageProps.runtimeConfig}>
            <Providers pageProps={rest.pageProps}>
                <MyApp {...rest} Component={Component} />
            </Providers>
        </RuntimeConfigProvider>
    </AuthProvider>
);
